"use client";
import Dropdown from "@/components/menuSuperior/Dropdown";
import { ptBR } from "@/i18n/pt-br";
import Logo from "@/public/images/logo_branca.png";
import { useUsuarioStore } from "@/store/usuarioStore";
import { CookieEnum } from "@/util/EnumUtils";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteCookie } from "cookies-next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { menuItems } from "./ItensMenuHeader";

const i18n = ptBR;

export default function Header() {
    const route = useRouter();
    const { usuario } = useUsuarioStore();
    const [menuAberto, isMenuAberto] = useState<Boolean>(false);

    const logoutUsuario = () => {
        deleteCookie(CookieEnum.FUNC_USUARIO, { path: "/", domain: `${process.env.NEXT_PUBLIC_DOMINIO}` });
        deleteCookie(CookieEnum.LOGIN_USUARIO, { path: "/", domain: `${process.env.NEXT_PUBLIC_DOMINIO}` });
        route.push("/login");
    };

    return (
        <header
            className={`fixed z-30 sm:sticky top-0 sm:flex h-full sm:h-[6%] w-0 sm:w-full sm:items-center sm:justify-around border-b border-black 
            bg-green-900  sm:px-4 shadow-sm text-white text-sm  ${menuAberto ? "flex items-start gap-2 h-full right-0 w-24 " : ""}`}>
            <button onClick={() => isMenuAberto(!menuAberto)}>
                <FontAwesomeIcon
                    icon={faBars}
                    size="2xl"
                    color="black"
                    className={`fixed sm:hidden right-10 ${menuAberto ? "right-28 top-0" : ""}`}
                />
            </button>
            <div
                className={`sm:flex h-full w-full items-center justify-around border-b ${menuAberto ? "flex flex-col" : "hidden"} 
                border-black bg-green-900 px-4 shadow-sm text-white text-sm`}>
                <nav className="flex w-11/12 items-center gap-4">
                    <Link
                        href={`${process.env.NEXT_PUBLIC_CORE_URL}/embarque/index.html`}
                        target="_blank"
                        className="hidden md:block">
                        <Image
                            src={Logo}
                            alt={i18n.alt_logo_rodosafra}
                            width={90}
                            height={21}
                            unoptimized
                        />
                    </Link>
                    <ul
                        className={`flex w-full items-center gap-2  ${menuAberto ? "flex-col gap-6" : "hidden sm:flex "}  md:gap-6 lg:gap-8`}>
                        <li>
                            <Link
                                href={`${process.env.NEXT_PUBLIC_CORE_URL}/carga/busca/busca.html`}
                                target="_blank">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    inverse
                                />
                            </Link>
                        </li>
                        {menuItems.map((item) => {
                            return item.itemFilho ? (
                                <Dropdown
                                    key={item.titulo}
                                    item={item}
                                />
                            ) : (
                                <li key={item.titulo}>
                                    <Link
                                        href={item.rota ?? "#"}
                                        target="_blank">
                                        {item.titulo}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                <nav className={`flex ${menuAberto ? "flex-col" : ""} justify-end gap-4 font-bold mr-2`}>
                    <span>{usuario?.login ?? "Não logado"}</span>
                    <button onClick={logoutUsuario}>Sair</button>
                </nav>
            </div>
        </header>
    );
}
