import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/menuSuperior/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/modal/Modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"italic\",\"normal\"],\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
